import { render, staticRenderFns } from "./browser.vue?vue&type=template&id=09eb34a6&scoped=true"
import script from "./browser.vue?vue&type=script&setup=true&lang=js"
export * from "./browser.vue?vue&type=script&setup=true&lang=js"
import style0 from "./browser.vue?vue&type=style&index=0&id=09eb34a6&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "09eb34a6",
  null
  
)

export default component.exports